import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react'
import { useState, useEffect, useRef } from "react";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';


class PostboardApp extends Component { 
  constructor(props){ 
    super(props) 
        
    // Set initial state 
    this.state = {
      items : []
    } 
        
    // Binding this keyword 
    // this.updateState = this.updateState.bind(this) 

    this.fileDialogRef = React.createRef();

  }

  handleSetOnlyYugiohPokemon  = (e) => {
    this.setState({onlyYugiohPokemon : e.target.checked})
  }

  handleFileRead = (e) => {
    const content = this.fileReader.result;
    // console.log(content)
    console.log(e)
    const items = [];

    const lines = content.split('\n');

    var currentCategory = "";

    for (const idx in lines) {
      const columns = lines[idx].split(',');

      if (columns[0] == "Qty") {
        continue;
      }

      if (columns[0] == "") {
        items[items.length - 1].condition = columns[1]
        continue;
      }

      if (!isNaN(columns[0])) {
        items.push({
          index: idx,
          category: currentCategory,
          quantity: columns[0],
          productName: columns[1]
        })
        continue;
      }

      currentCategory = columns[0];
    }


    function compare( a, b ) {
      console.log(`comparing ${a.productName} and ${b.productName}`);
      if ( a.productName < b.productName ){
        return -1;
      }
      if ( a.productName > b.productName ){
        return 1;
      }
      return 0;
    }
    
    items.sort( compare );


    this.setState({items : 
      items})
  };

  handleFileChosen = (file) => {
    this.fileReader = new FileReader();
    this.fileReader.onloadend = this.handleFileRead;
    this.fileReader.readAsText(file);
  };

  openFileDialog = () => {
    this.fileDialogRef.current.click();
  };

  render(){
    return (
      <div className="App">
        <header className="App-header">
          <p>Click the button to load CSV file</p>
          <Form>
            <Form.Check 
              type="switch"
              id="show-table"
              label="Only Show Pokemon/Yugioh Items"
              onChange={e => this.handleSetOnlyYugiohPokemon(e)}
            />
          </Form>
          <Button onClick={this.openFileDialog}>Open file upload box</Button>
          <input
            style={{display: 'none'}}
            ref={this.fileDialogRef}
            type="file"
            onChange={e => this.handleFileChosen(e.target.files[0])}
          />
        </header>
        <table className="table">
            <thead>
              <tr>
                <td>Product Name</td>
                <td>Category</td>
                <td>Condition</td>
                <td>Quantity</td>
              </tr>
            </thead>
            <tbody>
              {this.state.items.map((item,index)=>{

                if (this.state.onlyYugiohPokemon && !item.productName.includes(" - ")) {
                  return;
                }

                return (<tr key={item.index}>
                  <td>{item.productName}</td>
                  <td>{item.category}</td>
                  <td>{item.condition}</td>
                  <td>{item.quantity}</td>
                </tr>)
              })}
            </tbody>
          </table>
      </div>
    );
  }
}

export default PostboardApp;


